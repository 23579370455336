import { http } from '@/utils'
import { create } from 'zustand'

interface UserState {
  userInfo: {
    birthday: string
    gender: number
    id: string
    intro: string
    mobile: string
    name: string
    photo: string
  }
  getUserInfo: () => void
}

const useUserStore = create<UserState>()((set) => ({
  userInfo: {} as UserState['userInfo'],
  getUserInfo: async () => {
    const res = await http.post('/v1/user/profile')
    set({ userInfo: res.data })
  },
}))

export default useUserStore
