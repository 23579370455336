import { http } from '@/utils'
import { create } from 'zustand'

interface ChannelState {
  channels: { id: number; name: string }[]
  getChannels: () => void
}

const useChannelStore = create<ChannelState>()((set) => ({
  channels: [],
  getChannels: async () => {
    const res = await http.get('/channels')
    set({ channels: res.data.channels })
  },
}))

export default useChannelStore
