import { create } from 'zustand'

interface BatchState {
  sBeginAt: string
  sEndAt: string
  updateDate: (begin: string, end: string) => void
}

const useBatchStore = create<BatchState>()((set) => ({
  sBeginAt: '',
  sEndAt: '',
  updateDate: (begin, end) => set(() => ({ sBeginAt: begin, sEndAt: end })),
}))

export default useBatchStore
