import { getLocToken, http, removeLocToken, setLocToken } from '@/utils'
import { create } from 'zustand'

interface LoginState {
  token: string
  getToken: (params: { username: string; password: string }) => void
  loginOut: () => void
}

const useLoginStore = create<LoginState>()((set) => ({
  token: getLocToken() || '',
  getToken: async ({ username, password }) => {
    const res = await http.post(`${"https://fund.fanwaidi.com/api"}/login`, {
      username,
      password,
    })
    set({ token: res.data.token })
    setLocToken(res.data.token)
  },
  loginOut: () => {
    removeLocToken()
    set({ token: '' })
  },
}))

export default useLoginStore
