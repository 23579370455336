import useBatchStore from './batch.Store'
import useChannelStore from './channel.Store'
import useLoginStore from './login.Store'
import usePositionBatchStore from './positionbatch.Store'
import useUserStore from './user.Store'

// 创建 RootStore
const useRootStore = () => ({
  loginStore: useLoginStore(),
  userStore: useUserStore(),
  channelStore: useChannelStore(),
  positionBatchStore: usePositionBatchStore(),
  batchStore: useBatchStore(),
})

// 导出 useStore 钩子
export const useStore = () => useRootStore()
