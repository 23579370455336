import { http } from '@/utils'
import { create } from 'zustand'

interface Batch {
  id: number
  name: string
}

interface PositionBatchState {
  batchs: Batch[]
  getBatchs: () => Promise<void>
}

const usePositionBatchStore = create<PositionBatchState>()((set) => ({
  batchs: [],
  getBatchs: async () => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const res = await http.post('/v1/position/batch', {})
        set({ batchs: res.data.batchs })
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  },
}))

export default usePositionBatchStore
